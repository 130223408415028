import React from "react";
import Link from "gatsby-plugin-transition-link";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../../utils/linkResolver";
import htmlSerializer from "../../utils/htmlSerializer";

class Masthead extends React.Component {
  render() {
    return (
      <div
        className={`c-masthead${this.props.link ? " c-masthead--link" : ""}${
          this.props.cssClass ? " " + this.props.cssClass : ""
        }`}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="c-masthead__container">
              <h1 className="c-masthead__title">{this.props.title}</h1>
              {this.props.content && (
                <div className="c-masthead__content cms-content">
                  {RichText.render(
                    this.props.content,
                    linkResolver,
                    htmlSerializer
                  )}
                </div>
              )}
              {this.props.inlinecontent && (
                <div className="c-masthead__content cms-content">
                  <p>{this.props.inlinecontent}</p>
                </div>
              )}
            </div>
          </div>
          {!this.props.hidefooter && (
            <div className="row">
              <div className="c-masthead__footer">
                {!this.props.content && (
                  <span
                    className="c-masthead__footer-text"
                    id="masthead-footer-text"
                  >
                    Go deeper
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Masthead;
