import React from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Masthead from '../components/masthead/masthead'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

export const query = graphql`
  {
    prismic {
      allContactuss {
        edges {
          node {
            confirmationmessage
            metatitle
            metadescription
            title
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const doc = data.prismic.allContactuss.edges.slice(0, 1).pop()
  if (!doc) return null
  const title = RichText.asText(doc.node.title)
  const metaTitle = doc.node.metatitle ? RichText.asText(doc.node.metatitle) : title
  const metaDescription = RichText.asText(doc.node.metadescription)
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname="/contact-us-success"
      />
      <Masthead title={title} content={doc.node.confirmationmessage} cssClass="c-masthead--condensed"></Masthead>
    </Layout>
  )
}
